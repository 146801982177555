import React, { useEffect, useRef } from 'react';
import './tokenomics.css';

function Tokenomics() {
  const sectionRef = useRef(null);
  const supplyRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target === sectionRef.current) {
            entry.target.classList.add('fade-in');
          } else if (entry.target === supplyRef.current) {
            entry.target.classList.add('fade-in-up');
          }
        }
      });
    }, options);

    const currentSectionRef = sectionRef.current;
    const currentSupplyRef = supplyRef.current;

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }
    if (currentSupplyRef) {
      observer.observe(currentSupplyRef);
    }

    return () => {
      if (currentSectionRef) observer.unobserve(currentSectionRef);
      if (currentSupplyRef) observer.unobserve(currentSupplyRef);
    };
  }, []);

  return (
    <section id="tokenomics" className="tokenomics-section" ref={sectionRef}>
      <h1>Tokenomics</h1>
      <div className="tokenomics-info">
        <div className="tokenomics-item supply" ref={supplyRef}>
          <h2>Total Supply</h2>
          <p>1,000,000,000</p>
        </div>
        <div className="tokenomics-item">
          <h2>Tax</h2>
          <p>0%</p>
        </div>
      </div>
      <div className="contract-address">
        <h2>Contract Address</h2>
        <p>9p4MMz59V4S3TxP87hcAepxF5mq9iqN1jif9Jj9bpump</p>
      </div>
    </section>
  );
}

export default Tokenomics;
