import React from 'react';
import twitterIcon from '../assets/twitter.png';
import telegramIcon from '../assets/telegram.png';
import dexscreenerIcon from '../assets/dexscreener.png'
import './header.css';

function Header() {

  const handleScroll = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header>
      <nav>
        <div className="menu">
            <ul>
                <li><a href="#buy" onClick={(e) => handleScroll(e, 'buy')}>Buy</a></li>
                <li><a href="#tokenomics" onClick={(e) => handleScroll(e, 'tokenomics')}>Tokenomics</a></li>
                <li><a href="#memes" onClick={(e) => handleScroll(e, 'memes')}>Memes</a></li>
            </ul>
        </div>
        <div className="icons">
          <a href="https://x.com/fredisonsol" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} alt="Twitter Icon" />
          </a>
          <a href="https://t.me/+OR_7naB8IZs2Nzk0" target="_blank" rel="noopener noreferrer">
            <img src={telegramIcon} alt="Telegram Icon" />
          </a>
          <a href="https://dexscreener.com/solana/88j5gqgqswgawbufllyaw3jrmhwqenvparhmpjqse8zz" target="_blank" rel="noopener noreferrer">
            <img src={dexscreenerIcon} alt="Dexscreener Icon" />
          </a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
