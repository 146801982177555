import React, { useEffect, useRef } from 'react';
import meme1 from '../assets/meme1.jpg';
import meme2 from '../assets/meme2.jpg';
import meme3 from '../assets/meme3.jpg';
import meme4 from '../assets/meme4.jpg';
import meme5 from '../assets/meme5.jpg';
import meme6 from '../assets/meme6.jpg';
import meme7 from '../assets/meme7.jpg';
import meme8 from '../assets/meme8.jpg';
import meme9 from '../assets/meme9.jpg';
import meme10 from '../assets/meme10.jpg';
import meme11 from '../assets/meme11.jpg';
import meme12 from '../assets/meme12.jpg';
import meme13 from '../assets/meme13.jpg';
import meme14 from '../assets/meme14.jpg';
import meme15 from '../assets/meme15.jpg';
import meme16 from '../assets/meme16.jpg';
import meme17 from '../assets/meme17.jpg';
import meme18 from '../assets/meme18.jpg';
import meme19 from '../assets/meme19.jpg';
import meme20 from '../assets/meme20.jpg';

import './memes.css';

const memes = [meme1, meme2, meme3, meme4, meme5, meme6, meme7, meme8, meme9, meme10, meme11, meme12, meme13, meme14, meme15, meme16, meme17, meme18, meme19, meme20];

function Memes() {
  const memeRefs = useRef([]);

  useEffect(() => {
    const currentMemeRefs = memeRefs.current;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in-up');
        }
      });
    }, options);

    currentMemeRefs.forEach((meme) => {
      if (meme) {
        observer.observe(meme);
      }
    });

    return () => {
      currentMemeRefs.forEach((meme) => {
        if (meme) observer.unobserve(meme);
      });
    };
  }, []);

  return (
    <section id="memes" className="memes-section">
      <h1>Memes</h1>
      <div className="memes-grid">
        {memes.map((meme, index) => (
          <div 
            key={index} 
            className="meme-item"
            ref={(el) => memeRefs.current[index] = el}
          >
            <img src={meme} alt={`Meme ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Memes;