import React from 'react';
import './liner.css';

function CoinLine() {
  return (
    <div className="coin-line">
      <div className="coin-text">
        $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there
      </div>
      <div className="coin-text">
      $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there $FRED was there 
      </div>
    </div>
  );
}

export default CoinLine;
