import React from 'react';
import './buy.css';

function Buy() {
  return (
    <section id="buy" className="buy-section">
      <div className="buy-content">
        <h1>FRED</h1>
        <p>Fred was there</p>
        <a href="https://pump.fun/9p4MMz59V4S3TxP87hcAepxF5mq9iqN1jif9Jj9bpump#p9163259" target="_blank" rel="noopener noreferrer">
          <button className="cool-button">BUY $FRED</button>
        </a>
      </div>
    </section>
  );
}

export default Buy;
