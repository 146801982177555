import React from 'react';
import Header from './structure/header';
import Buy from './structure/buy';
import Tokenomics from './structure/tokenomics';
import Memes from './structure/memes';
import Liner from './structure/liner'

function App() {
  return (
    <div>
      <Header />
      <Buy />
      <Liner />
      <Tokenomics />
      <Liner />
      <Memes />
      <Liner />
    </div>
  );
}

export default App;